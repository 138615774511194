import React, {useState} from "react";
import {Button, Col, Form} from "react-bootstrap";
import {Formik} from 'formik';
import * as Yup from "yup";
import axios from "axios";


const EgoForm = ({handleSuccess, handleError}) => {

	const [submitting, setSubmitting] = useState(false);

	const subscribeMailchimp = async (values) => {
		const url = 'https://uo1pe2eev8.execute-api.us-east-1.amazonaws.com/rest/subscribe';
		setSubmitting(true);
		try {
			const response = await axios.post(url, values);
			handleSuccess(response.data.message);
		} catch (e) {
			if (e.response.status === 400) {
				handleError(e.response.data.message);
			}
		}
		setSubmitting(false);
	};

	return (
		<Formik
			initialValues={{
				'firstName': '',
				'lastName': '',
				'email': '',
				'xpert': ''
			}}
			validationSchema={Yup.object().shape({
				firstName: Yup.string().required('Required'),
				lastName: Yup.string().required('Required'),
				email: Yup.string().email('Must be an email address').required('Required'),
				xpert: Yup.string().required('Required'),
				rating: Yup.number().integer().required('Required'),
				subscribed: Yup.bool().required('Required')
			})}
			onSubmit={values => subscribeMailchimp(values)}
		>

			{({handleChange, handleSubmit, handleBlur, touched, values, errors, isValid, dirty, getFieldProps}) => (
				<Form noValidate onSubmit={handleSubmit}>
					<Form.Row>
						<Col>
							<h2 className={"text-center font-weight-light"}>
								Join the #1 Rated Battery Powered Platform
							</h2>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Group controlId={"formFirstName"}>
								<Form.Label>First Name</Form.Label>
								<Form.Control type={"text"} name={"firstName"}
								              onChange={handleChange}
								              onBlur={handleBlur}
								              value={values.firstName}
								              isInvalid={touched.firstName && errors.firstName}
								              isValid={touched.firstName && !errors.firstName}/>
							</Form.Group>
						</Col>
						<Col>
							<Form.Group controlId={"formLastName"}>
								<Form.Label>Last Name</Form.Label>
								<Form.Control type={"text"} name={"lastName"}
								              onChange={handleChange}
								              onBlur={handleBlur}
								              value={values.lastName}
								              isValid={touched.lastName && !errors.lastName}
								              isInvalid={touched.lastName && errors.lastName}/>
							</Form.Group>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Group controlId={"formEmail"}>
								<Form.Label>Email</Form.Label>
								<Form.Control type={"email"} name={"email"}
								              onChange={handleChange}
								              onBlur={handleBlur}
								              value={values.email}
								              isValid={touched.email && !errors.email}
								              isInvalid={touched.email && errors.email}/>
							</Form.Group>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Group controlId={"formXpertName"}>
								<Form.Label>Xpert Name</Form.Label>
								<Form.Control type={"text"} placeholder={"First + Last"} name={"xpert"}
								              onChange={handleChange}
								              onBlur={handleBlur}
								              value={values.xpert}
								              isValid={touched.xpert && !errors.xpert}
								              isInvalid={touched.xpert && errors.xpert}/>
							</Form.Group>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Group controlId={"formXpertRating"}>
								<Form.Label>Let us know how this Xpert did!<br/>How would you rate your interaction with
									them today?</Form.Label>
								<Form.Control as={"select"} name={"rating"}
								              onChange={handleChange}
								              onBlur={handleBlur}
								              value={values.rating}
								              isInvalid={touched.rating && errors.rating}>
									<option>---</option>
									<option value={1}>Very Poor</option>
									<option value={2}>Poor</option>
									<option value={3}>Okay</option>
									<option value={4}>Great</option>
									<option value={5}>Excellent</option>
								</Form.Control>
							</Form.Group>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Form.Check
								type={'checkbox'}
								label={'Yes, I want to receive emails from EGO and it\'s partners about special events and offers'}
								name={'subscribed'}
								{...getFieldProps('subscribed')}
							/>
						</Col>
					</Form.Row>
					<Form.Row>
						<Col>
							<Button variant={"primary"} block size={"lg"} type={"Submit"}
							        disabled={!(isValid && dirty) || submitting} className={"mt-3"}>
								Join Now
							</Button>
						</Col>
					</Form.Row>
				</Form>
			)}
		</Formik>
	);
};

export default EgoForm;
