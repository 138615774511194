import React, {useEffect, useState} from "react";
import {Container, Navbar, Row, Col} from "react-bootstrap";
import {ToastContainer, toast, Slide} from "react-toastify";
import logo from './ego_logo.png';
import hero from './ego_hero.jpg';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import EgoForm from "./components/EgoForm/EgoForm";


const App = () => {
	const [error, setError] = useState(false);
	const [message, setMessage] = useState(false);

	const handleError = (message) => {
		setError(message);
	};

	const handleSuccess = (message) => {
		setMessage(message);
	}

	useEffect(() => {
		const notify = () => toast.warn(error,
			{
				position: "top-center",
				autoClose: false,
				hideProgressBar: true,
				transition: Slide,
				onClose: () => {
					setError(false);
				}
			}
		);

		error && notify();

	}, [error]);

	return (
		<div className={"main"}>
			<Navbar bg={"light"} expand={"lg"}>
				<Navbar.Brand>
					<img src={logo} alt="logo" className={"img-fluid logo"}/>
				</Navbar.Brand>
			</Navbar>
			<Container fluid className={"h-100"}>
				<ToastContainer draggable={false} limit={1}/>
				<Row className={"hero-block"}>
					<Col className={"p-0"}>
						<img src={hero} id={"hero-image"} alt={"ego-hero"}/>
					</Col>
				</Row>
				<Row className={"form-block h-100 d-flex align-items-center pt-2"}>
					<Col sm={2} lg={3} className={"d-none d-md-block"}/>
					<Col className={"p-2 p-sm-3 p-xl-5"}>
						<Container className={"form-container p-3 p-sm-4 p-xl-5"}>
							{message ?
								<h3 className={"text-center m-5 subscription-success"} >You've been subscribed!</h3>
								:
								<EgoForm handleError={handleError} handleSuccess={handleSuccess}/>
							}
						</Container>
					</Col>
					<Col sm={2} lg={3} className={"d-none d-md-block"}/>
				</Row>
			</Container>
		</div>
	);
}

export default App;
